import { MAINTAIN_PERMISSION, GROWERS_SAMPLEBINS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Sample Bins",
      "search-by": "BinNumber,IntakeNumber"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var record = _ref.record, toggleActive = _ref.toggleActive;
        return [_vm.hasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "list__action-toggle-active mr-2",
          attrs: {
            "icon": record.isInactive ? "eye-invisible" : "eye",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return toggleActive(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("url-field", {
    key: "BinNumber",
    attrs: {
      "data-index": "binNumber",
      "title": "Bin Number",
      "base-url": "/watties-grower/sample-bins#"
    }
  }), _c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code",
      "parse": _vm.parseCropCode
    }
  }), _c("text-field", {
    key: "DateCreated",
    attrs: {
      "data-index": "dateCreated",
      "title": "Date Created",
      "parse": _vm.parseDateCreated
    }
  }), _c("text-field", {
    key: "IntakeNumber",
    attrs: {
      "data-index": "intakeNumber",
      "title": "Intake Code"
    }
  }), _c("text-field", {
    key: "Warehouse",
    attrs: {
      "data-index": "warehouse",
      "title": "Warehouse"
    }
  }), _c("text-field", {
    key: "Sscc",
    attrs: {
      "data-index": "sscc",
      "title": "SSCC"
    }
  }), _c("text-field", {
    key: "Location",
    attrs: {
      "data-index": "location",
      "title": "Location"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "SampleBins",
  inject: ["can"],
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  methods: {
    parseDateCreated(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY hh:mm A");
    },
    parseCropCode(value, record) {
      if (!value)
        return "";
      return `${value} ${record.cropName}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var SampleBins = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "sample-bins"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.common.ware-houses", "grower.common.crops"],
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.sample-bins",
      "api-url": _vm.apiUrl,
      "create-route": "sample-bins/create-sample-bin",
      "filter-route": "sample-bins/advanced-search",
      "page": _vm.page
    }
  }, [_c("sample-bins")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    SampleBins
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_SAMPLEBINS,
      menuItems: [
        {
          key: "intakeprocessing",
          title: "Intake Processing",
          path: ""
        },
        {
          key: "samplebins",
          title: "Sample Bins",
          path: "/watties-grower/sample-bins"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
